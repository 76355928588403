import { apiSlice } from "../api/apiSlice";

export interface Unpaid {
    clientId: string;
    amount: number;
}

export interface Receipt {
    id?: string;
    number?: string;
    clientId: string;
    date?: string;
    amount: number;
}

export const receiptingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUnpaidAmounts: builder.query<Unpaid[], void>({
            query: () => 'receipting',
            providesTags: ['invoices']
        }),
        receivePayment: builder.mutation<Receipt, Receipt>({
            query: (receipt) => ({
                url: "receipting/receive",
                method: "POST",
                body: receipt
            }),
            invalidatesTags: ['invoices', 'receipts', 'credit-notes']
        })
    })
})

export const {
    useGetUnpaidAmountsQuery,
    useReceivePaymentMutation
} = receiptingApiSlice