import { createEntityAdapter, createSelector, EntityState } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { apiSlice } from "../api/apiSlice";
import { CreditNote, Invoice } from "../billing/billingSlice";
import { Receipt } from "../receipting/receiptingSlice";
import { Session } from "../session-log/sessionSlice";

export interface Client {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    rate: number;
    defaultSession: string;
    due: number;
    outstanding: number;
}

export interface AccountSummary {
    invoiced: number;
    credit: number;
    sessions: number;
}

const clientsAdapter = createEntityAdapter<Client>();

const initialState = clientsAdapter.getInitialState()

export const clientsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClientList:  builder.query<Client[], void>({
            query: () => 'clients',
            providesTags: ["clients"]
        }),
        getClients: builder.query<EntityState<Client>, void>({
            query: () => 'clients',
            providesTags: ["clients"],
            transformResponse: (responseData: Client[]) => {
                return clientsAdapter.setAll(initialState, responseData);
            }
        }),
        upsertClient: builder.mutation<Client, Client>({
            query: (client) => ({
                url: `clients/${client.id}`,
                method: 'POST',
                body: {
                    ...client,
                    rate: Number(client.rate)
                },
            }),
            invalidatesTags: ["clients"],
        }),
        deleteClient: builder.mutation({
            query: (client) => ({
                url: `clients/delete/${client.id}`,
                method: 'POST',
            }),
            invalidatesTags: ["clients"],
        }),
        getClientInvoices: builder.query<Invoice[], string>({
            query: (clientId) => `clients/${clientId}/invoices`,
            providesTags: ["invoices"]
        }),
        getClientSessions: builder.query<Session[], string>({
            query: (clientId) => `clients/${clientId}/sessions`,
            providesTags: ["sessions"]
        }),
        getClientReceipts: builder.query<Receipt[], string>({
            query: (clientId) => `clients/${clientId}/receipts`,
            providesTags: ["receipts"]
        }),
        getClientCreditNotes: builder.query<CreditNote[], string>({
            query: (clientId) => `clients/${clientId}/credit-notes`,
            providesTags: ["credit-notes"]
        }),
        getClientAccountSummary: builder.query<AccountSummary, string>({
            query: (clientId) => `clients/${clientId}/account-summary`,
            providesTags: ["sessions", "invoices", "credit-notes"]
        }),
    })
});

export const {
    useGetClientListQuery,
    useGetClientsQuery,
    useUpsertClientMutation,
    useDeleteClientMutation,
    useGetClientInvoicesQuery,
    useGetClientSessionsQuery,
    useGetClientReceiptsQuery,
    useGetClientCreditNotesQuery,
    useGetClientAccountSummaryQuery,
} = clientsApiSlice

export const selectClientsResult = clientsApiSlice.endpoints.getClients.select()

// Creates memoized selector
const selectPostsData = createSelector(
    selectClientsResult,
    clientsResult => clientsResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllClients,
    selectById: selectClientById
} = clientsAdapter.getSelectors<RootState>(state => selectPostsData(state) ?? initialState)