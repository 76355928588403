import { useDisclosure, GridItem, Box, Heading, chakra, Stack, SimpleGrid, FormControl, FormLabel, Input, InputGroup, InputLeftAddon, Tooltip, Button, Text } from "@chakra-ui/react";
import { useState, SyntheticEvent } from "react";
import { FiBriefcase, FiDollarSign, FiRotateCcw, FiTrash } from "react-icons/fi";
import { useNavigate, Link } from "react-router-dom";
import { Client, useUpsertClientMutation } from "./clientsSlice";
import { DeleteConfirmation } from "./DeleteConfirmation";
import { ReceiptDialog } from "./ReceiptDialog";

export const ClientDetails = ({currentClient}: {currentClient: Client}) => {
    const [client, setClient] = useState(currentClient);
    const [upsetClient] = useUpsertClientMutation();
    const navigate = useNavigate();
    const deleteDialog = useDisclosure();
    const receiptDialog = useDisclosure();
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target;
      const name = event.target.name;
      setClient({ ...client, [name]: target.value });
    }
  
    const onSaveClient = async (event: SyntheticEvent) => {
      event.preventDefault();
  
      await upsetClient(client);
      navigate("/clients");
    }
  
    return <><GridItem colSpan={{ md: 1 }}>
      <Box px={[4, 0]}>
        <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
          Personal Information
        </Heading>
        <Text
          mt={1}
          fontSize="sm"
          color="gray.600"
          _dark={{ color: "gray.400" }}
        >
          Some information about the client.
        </Text>
      </Box>
    </GridItem>
      <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
        <chakra.form
          method="POST"
          shadow="base"
          rounded={[null, "md"]}
          overflow={{ sm: "hidden" }}
        >
          <Stack
            px={4}
            py={5}
            p={[null, 6]}
            bg="white"
            _dark={{ bg: "#141517" }}
            spacing={6}
          >
            <SimpleGrid columns={6} spacing={6}>
              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="first_name"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{ color: "gray.50" }}
                >
                  First name
                </FormLabel>
                <Input
                  type="text"
                  name="firstName"
                  id="first_name"
                  autoComplete="given-name"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  w="full"
                  rounded="md"
                  value={client.firstName}
                  onChange={handleChange}
                />
              </FormControl>
  
              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="last_name"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{ color: "gray.50" }}
                >
                  Last name
                </FormLabel>
                <Input
                  type="text"
                  name="lastName"
                  id="last_name"
                  autoComplete="family-name"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  w="full"
                  rounded="md"
                  value={client.lastName}
                  onChange={handleChange}
                />
              </FormControl>
  
              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="email_address"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{ color: "gray.50" }}
                >
                  Email address
                </FormLabel>
                <Input
                  type="email"
                  name="email"
                  id="email_address"
                  autoComplete="email"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  w="full"
                  rounded="md"
                  value={client.email}
                  onChange={handleChange}
                />
              </FormControl>
  
              <FormControl as={GridItem} colSpan={[6, 3]}>
                <FormLabel
                  htmlFor="rate"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{ color: "gray.50" }}
                >
                  Rate
                </FormLabel>
                <InputGroup rounded="md" w="full" mt={1} shadow="sm">
                  <InputLeftAddon children='$' />
                  <Input
                    type="number"
                    name="rate"
                    id="rate"
                    focusBorderColor="brand.400"
                    value={client.rate}
                    onChange={handleChange}
                  />
                </InputGroup>
              </FormControl>
  
              <FormControl as={GridItem} colSpan={[12, 6]}>
                <FormLabel
                  htmlFor="rate"
                  fontSize="sm"
                  fontWeight="md"
                  color="gray.700"
                  _dark={{ color: "gray.50" }}
                >
                  Default Session Description
                </FormLabel>
                <Input
                  type="text"
                  name="defaultSession"
                  id="session_description"
                  mt={1}
                  focusBorderColor="brand.400"
                  shadow="sm"
                  w="full"
                  rounded="md"
                  value={client.defaultSession}
                  onChange={handleChange}
                />
              </FormControl>
  
            </SimpleGrid>
          </Stack>
          <Box
            px={{ base: 4, sm: 6 }}
            py={3}
            bg="gray.50"
            _dark={{ bg: "#121212" }}
            display="flex"
            justifyContent="space-between"
          >
            <span>
              {client.id !== "new" && <>
                <Link to={`/billing/${client.id}`}>
                  <Tooltip hasArrow label="Invoice">
                    <Button variant="outline" colorScheme="teal"><FiBriefcase /></Button>
                  </Tooltip>
                </Link>
                <Tooltip hasArrow label="Receipt">
                  <Button onClick={receiptDialog.onOpen} variant="outline" colorScheme="teal"><FiDollarSign /></Button>
                </Tooltip>
                <Tooltip hasArrow label="Refund">
                  <Button variant="outline" colorScheme="teal"><FiRotateCcw /></Button>
                </Tooltip>
              </>}
            </span>
            <span>
              {client.id !== "new" && <Button
                variant='ghost'
                onClick={deleteDialog.onOpen}
              >
                <FiTrash />
              </Button>}&nbsp;
              <Button
                type="submit"
                colorScheme="teal"
                _focus={{ shadow: "" }}
                fontWeight="md"
                onClick={onSaveClient}
              >
                Save Client
              </Button>
            </span>
          </Box>
        </chakra.form>
      </GridItem>
  
      <DeleteConfirmation client={client} {...deleteDialog} />
      <ReceiptDialog client={client} {...receiptDialog} />
    </>
  }