import { Route, Routes } from "react-router-dom"
import { EditClient } from "./EditClient"
import { List } from "./List"

export const Clients = () => {
    return (
        <Routes>
            <Route path=":clientId" element={<EditClient />} />
            <Route index element={<List />} />
        </Routes>
    )
}