import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User } from "../api/User";

export interface AuthState {
  user?: User,
  token: string
}

const initialState: AuthState = {
  user: undefined,
  token: ''
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
})

// Action creators are generated for each case reducer function
export const { login } = authSlice.actions

export default authSlice.reducer