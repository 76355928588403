import { AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter, Button } from "@chakra-ui/react";
import { useRef, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import { Client, useDeleteClientMutation } from "./clientsSlice";

export const DeleteConfirmation = ({isOpen, onClose, client}: {isOpen: boolean, onClose: () => void, client: Client}) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const [deleteClient] = useDeleteClientMutation();
    const navigate = useNavigate();
  
    const onDeleteClient = async (event: SyntheticEvent) => {
      event.preventDefault();
  
      await deleteClient(client);
      navigate("/clients");
    }
  
    return <AlertDialog
    isOpen={isOpen}
    leastDestructiveRef={cancelRef}
    onClose={onClose}
  >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Delete Client
          </AlertDialogHeader>
  
          <AlertDialogBody>
            Are you sure? You can't undo this action afterwards.
          </AlertDialogBody>
  
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='red' onClick={onDeleteClient} ml={3}>
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  }