import { Container, useColorModeValue, Button, Flex, FormControl, Input, VStack, Checkbox, Stack, Skeleton, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Client, selectAllClients } from "../clients/clientsSlice";
import { Session, useAddSessionMutation, useDeleteSessionMutation, useGetSessionsQuery } from "./sessionSlice";
import { FiLoader, FiCheck, FiCalendar } from "react-icons/fi";
import { addDays, dateOnly, today } from "../api/apiSlice";
import { Avatar } from "../clients/Avatar";
import { useNavigate } from "react-router-dom";

export const Day = () => {
    const [date, setDate] = useState(today());
    const clients = useSelector(selectAllClients);
    const { data: sessions, isLoading } = useGetSessionsQuery({year: date.getFullYear(), month: date.getMonth() + 1});
    const [isLargeScreen] = useMediaQuery('(min-width: 1200px)');
    const navigate = useNavigate();

    const Loading = () => {
        return <Stack>
        <Skeleton height='32px' />
        <Skeleton height='32px' />
      </Stack>
    }

    return <Container
        maxW={'xl'}
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        boxShadow={'md'}
        rounded={'lg'}
        p={6}>
        <VStack spacing={5} align="stretch">
            <Flex>
                <Button onClick={() => { setDate(addDays(date, -1)) }}>&lt;</Button>
                <FormControl>
                    <Input type="date" value={date.toISOString().split('T')[0]} onChange={(e) => { setDate(dateOnly(e.target.valueAsDate)) }} />
                </FormControl>
                {isLargeScreen && <Button onClick={() => { navigate("calender") }} leftIcon={<FiCalendar />}></Button>}
                <Button onClick={() => { setDate(addDays(date, 1)) }}>&gt;</Button>
            </Flex>
            {isLoading ? <Loading /> : <EditSessions clients={clients} sessions={sessions} date={date} />}
        </VStack>
    </Container>
}

const LoadingIcon = (props: any) => {
    const { isIndeterminate } = props;
    return (isIndeterminate ? <FiLoader className="spin" /> : <FiCheck />)
}

export const EditSessions = ({clients, sessions, date}: {clients: Array<Client>, sessions: Array<Session> | undefined, date: Date}) => 
    <VStack spacing={4}>{clients.map(client => <SessionCheckbox key={client.id} client={client} sessions={sessions} date={date} />)}</VStack>

const SessionCheckbox = ({client, sessions, date}: {client: Client, sessions?: Session[], date: Date}) => {
    
    const session = sessions?.find(s => dateOnly(s.date).getTime() === date.getTime() && s.clientId === client.id);

    const [addSession, {isLoading: adding}] = useAddSessionMutation();
    const [deleteSession, {isLoading: deleting}] = useDeleteSessionMutation();
    const updateSession = (clientId: string, checked: boolean) => {
        if(adding || deleting) {
            return;
        }
        if(checked) {
            addSession({
                id: new Date().getTime().toString(),
                date: date.toISOString(),
                clientId: clientId
            });
        } else {
            deleteSession(session);
        }
    }

    return <FormControl key={client.id}>
        <Checkbox icon={<LoadingIcon />} isDisabled={!!session?.invoiceId} isIndeterminate={adding || deleting} size="lg" isChecked={!!session} onChange={(event) => updateSession(client.id, event.target.checked)}><Avatar client={client} size="xs" /> {client.firstName} {client.lastName}</Checkbox>
    </FormControl>
}