import { Session } from "../session-log/sessionSlice";
import { apiSlice } from "../api/apiSlice";

export interface SessionToBeBulled {
    clientId: string;
    sessionCount: number;
}

export interface Invoice {
    id?: string;
    number?: string;
    date?: string;
    clientId: string;
    status?: string;
    items: LineItem[]
}

export interface LineItem {
    id: string;
    date?: string;
    sessionId?: string;
    description: string;
    quantity: number;
    unitPrice: number;
}

export interface CreditNote {
    id?: string;
    number?: string;
    date?: string;
    amount: number;
    redeemed?: number;
    status: string;
}

export const billingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getOutstandingSessions: builder.query<SessionToBeBulled[], void>({
            query: (date) => `outstanding-sessions`,
            providesTags: ['sessions']
        }),
        getClientOutstandingSessions: builder.query<Session[], string>({
            query: (clientId) => `outstanding-sessions/${clientId}`
        }),
        raiseInvoice: builder.mutation<Invoice, Invoice>({
            query: (invoice) => ({
                url: `billing/invoice`,
                method: 'POST',
                body: invoice,
            }),
            invalidatesTags: ['sessions', 'invoices', 'stats']
        }),
    })
});

export const { 
    useGetOutstandingSessionsQuery,
    useGetClientOutstandingSessionsQuery,
    useRaiseInvoiceMutation,
} = billingApiSlice;