import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import { LoggedIn } from "./LoggedIn";

export const App = () => {
  return <ChakraProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="*" element={<LoggedIn />} />
      </Routes>
    </Router>
  </ChakraProvider>
}
