import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, FormControl, FormLabel, Input, InputGroup, InputLeftElement, ModalFooter, Button, useToast } from "@chakra-ui/react";
import { useRef, useState, SyntheticEvent } from "react";
import { FiDollarSign } from "react-icons/fi";
import { useReceivePaymentMutation } from "../receipting/receiptingSlice";
import { Client } from "./clientsSlice";

export const ReceiptDialog = ({isOpen, onClose, client}: {isOpen: boolean, onClose: () => void, client: Client}) => {
    const cancelRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [amount, setAmount] = useState(0);
    const [receivePayment, {isLoading}] = useReceivePaymentMutation();
    const toast = useToast();
  
    const onReceivePayment = async (event: SyntheticEvent) => {
      event.preventDefault();
  
      var receipt = {clientId: client.id, amount};
      await receivePayment(receipt);
      onClose();
      toast({
        title: "Receipt Created",
        colorScheme: "teal",
        description: `A receipt for $${amount} has been created`
      });
    }
  
    return <Modal
    isOpen={isOpen}
    initialFocusRef={inputRef}
    onClose={onClose}
  >
      <ModalOverlay>
        <ModalContent>
          <ModalHeader fontSize='lg' fontWeight='bold'>
            Receipt
          </ModalHeader>
  
          <ModalBody>
            <FormControl>
              <FormLabel>Client</FormLabel>
              <FormControl>
                <Input value={`${client.firstName} ${client.lastName}`} />
              </FormControl>
            </FormControl>
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <FormControl>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents='none'
                    children={<FiDollarSign color='gray.300' />}
                  />
                  <Input ref={inputRef} onFocus={(e) => e.target.select()} type="number" value={amount} onChange={(e) => setAmount(e.target.valueAsNumber)} />
                </InputGroup>
              </FormControl>
            </FormControl>
          </ModalBody>
  
          <ModalFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='teal' onClick={onReceivePayment} ml={3} disabled={isLoading}>
              Receipt
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  }