import { GridItem, Box, Heading, Table, Thead, Tr, Th, Tbody, Td, Text } from "@chakra-ui/react";
import { formatDate } from "../api/apiSlice";
import { Client, useGetClientReceiptsQuery } from "./clientsSlice";

export const ReceiptList = ({client}: {client: Client}) => {
    const {data} = useGetClientReceiptsQuery(client.id);
  
    return <><GridItem colSpan={{ md: 1 }}>
    <Box px={[4, 0]}>
      <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
        Receipts
      </Heading>
      <Text
        mt={1}
        fontSize="sm"
        color="gray.600"
        _dark={{ color: "gray.400" }}
      >
        These are the latest 10 receipts.
      </Text>
    </Box>
  </GridItem>
  <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
    <Box
      px={4}
      py={5}
      p={[null, 6]}
      bg="white"
      _dark={{ bg: "#141517" }}
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Number</Th>
            <Th>Date</Th>
            <Th isNumeric>Amount</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(r => <Tr key={r.id}>
            <Td>{r.number}</Td>
            <Td>{formatDate(r.date)}</Td>
            <Td isNumeric>${r.amount}</Td>
          </Tr>)}
        </Tbody>
      </Table>
    </Box>
  </GridItem></>
  }