import { GridItem, Box, Heading, Table, Thead, Tr, Th, Tbody, Td, Text, Stack, Skeleton } from "@chakra-ui/react";
import { Client, useGetClientAccountSummaryQuery } from "./clientsSlice";

export const AccountSummary = ({ client }: { client: Client }) => {
  const { data, isLoading } = useGetClientAccountSummaryQuery(client.id);

  const Loading = () => {
    return <Stack>
      <Skeleton height='32px' />
      <Skeleton height='40px' />
    </Stack>
  }

  return <><GridItem colSpan={{ md: 1 }}>
    <Box px={[4, 0]}>
      <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
        Account Summary
      </Heading>
      <Text
        mt={1}
        fontSize="sm"
        color="gray.600"
        _dark={{ color: "gray.400" }}
      >
        Summary of account to date
      </Text>
    </Box>
  </GridItem>
    <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
      <Box
        px={4}
        py={5}
        p={[null, 6]}
        bg="white"
        _dark={{ bg: "#141517" }}
      >
        {isLoading || !data ? <Loading /> : <Table>
          <Thead>
            <Tr>
              <Th>Outstanding</Th>
              <Th>Due</Th>
              <Th>Credit</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>${data.invoiced - data.credit}</Td>
              <Td>${data.sessions * client.rate}</Td>
              <Td>${data.credit}</Td>
            </Tr>
          </Tbody>
        </Table>}
      </Box>
    </GridItem></>
}