import { GridItem, Box, Heading, Table, Thead, Tr, Th, Tbody, Td, Text, Tag } from "@chakra-ui/react";
import { formatDate } from "../api/apiSlice";
import { CreditNote } from "../billing/billingSlice";
import { Client, useGetClientCreditNotesQuery } from "./clientsSlice";

export const CreditNoteList = ({client}: {client: Client}) => {
    const {data} = useGetClientCreditNotesQuery(client.id);

    const statusColor = (credit: CreditNote) => {
      switch(credit.status){
        case "FullyRedeemed": return "gray";
        default: return "teal";
      }
    }
  
    return <><GridItem colSpan={{ md: 1 }}>
    <Box px={[4, 0]}>
      <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
        Credit Notes
      </Heading>
      <Text
        mt={1}
        fontSize="sm"
        color="gray.600"
        _dark={{ color: "gray.400" }}
      >
        These are the latest 10 credit notes.
      </Text>
    </Box>
  </GridItem>
  <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
    <Box
      px={4}
      py={5}
      p={[null, 6]}
      bg="white"
      _dark={{ bg: "#141517" }}
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Number</Th>
            <Th>Date</Th>
            <Th isNumeric>Amount</Th>
            <Th>Status</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(c => <Tr key={c.id}>
            <Td>{c.number}</Td>
            <Td>{formatDate(c.date)}</Td>
            <Td isNumeric>${c.amount}</Td>
            <Td>
              <Tag colorScheme={statusColor(c)}>{c.status}</Tag>
            </Td>
          </Tr>)}
        </Tbody>
      </Table>
    </Box>
  </GridItem></>
  }