import { Container, Skeleton, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react"
import { formatCurrency, toMonth } from "../api/apiSlice";
import { Stats, useGetStatsQuery } from "./homeSlice"

export const Home = () => {
    const {data, isSuccess} = useGetStatsQuery();

    const Loading = ({count}:{count: number}) => {
        var rows = [];
        for(let i = 0; i < count; i++) {
            rows.push(<Skeleton key={i} height='32px' />)
        }

        return <Stack>
            <Skeleton height='22px' />
            {rows}
        </Stack>
    }

    const thisMonth = (data: Stats) => {
        const today = new Date();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        return data.months.find(m => m.month === month && m.year === year) 
            ?? {month: month, year: year, total: 0, amount: 0};
    }

    return <Stack alignItems="center">
    <Container
    maxW='1000px'
    bg={useColorModeValue('white', 'whiteAlpha.100')}
    boxShadow={'md'}
    rounded={'lg'}
    p={6}>
        {isSuccess && data ?
        <TableContainer>
            <Table>
                <Thead>
                    <Tr>
                        <Th>Month</Th>
                        <Th isNumeric>Revenue</Th>
                        <Th isNumeric>Invoiced</Th>
                        <Th isNumeric>Income</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {data.months.map(m => 
                    <Tr key={m.year + "" + m.month}>
                        <Td>{m.year} {toMonth(m.month)}</Td>
                        <Td isNumeric>{formatCurrency(m.earned)}</Td>
                        <Td isNumeric>{formatCurrency(m.total)}</Td>
                        <Td isNumeric>{formatCurrency(m.amount)}</Td>
                    </Tr>
                    )}
                </Tbody>
            </Table>
        </TableContainer>
         : <Loading count={2} />}
    </Container>
    
    <Container
    maxW={'xl'}
    bg={useColorModeValue('white', 'whiteAlpha.100')}
    boxShadow={'md'}
    rounded={'lg'}
    p={6}>
        {isSuccess && data ?
        <TableContainer>
            <Table>
                <Tbody>
                    <Tr>
                        <Th>Total Outstanding</Th><Td isNumeric>{formatCurrency(data.totalOutstanding)}</Td>
                    </Tr>
                    <Tr>
                        <Th>Total Due</Th><Td isNumeric>{formatCurrency(data.totalDue)}</Td>
                    </Tr>
                    <Tr>
                        <Th>{toMonth(new Date().getMonth() + 1)} Due + Invoiced</Th><Td isNumeric>{formatCurrency(data.totalDue + thisMonth(data).total)}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </TableContainer>
        : <Loading count={2} />}
    </Container>
    </Stack> 
}