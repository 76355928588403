import { GridItem, Box, Heading, Table, Thead, Tr, Th, Tbody, Td, Tag, Text } from "@chakra-ui/react";
import { FiCheck } from "react-icons/fi";
import { formatDate } from "../api/apiSlice";
import { Client, useGetClientSessionsQuery } from "./clientsSlice";

export const SessionList = ({client}: {client: Client}) => {
    const {data} = useGetClientSessionsQuery(client.id);
  
    return <><GridItem colSpan={{ md: 1 }}>
    <Box px={[4, 0]}>
      <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
        Sessions
      </Heading>
      <Text
        mt={1}
        fontSize="sm"
        color="gray.600"
        _dark={{ color: "gray.400" }}
      >
        These are the latest 10 sessions.
      </Text>
    </Box>
  </GridItem>
  <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
    <Box
      px={4}
      py={5}
      p={[null, 6]}
      bg="white"
      _dark={{ bg: "#141517" }}
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Date</Th>
            <Th>Invoiced</Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(s => <Tr key={s.id}>
            <Td>{formatDate(s.date)}</Td>
            <Td>{s.invoiceId && <Tag colorScheme="teal"><FiCheck /></Tag>}</Td>
          </Tr>)}
        </Tbody>
      </Table>
    </Box>
  </GridItem></>
  }