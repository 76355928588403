import { Button, Container, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { EditSessions } from "./Day";
import { addMonths, dateOnly } from "../api/apiSlice";
import { AvatarGroup } from "../clients/Avatar";
import { Client, selectAllClients } from "../clients/clientsSlice";
import { useGetSessionsQuery } from "./sessionSlice";
import { FiList } from "react-icons/fi"
import { useNavigate } from "react-router-dom";

const NonDay = () => {
    return <td></td>
}

const Day = ({ date, clients, onEdit }: { date: Date, clients: Array<Client | undefined>, onEdit: (dt: Date) => void }) => {
    return <td style={{ border: '1px solid #aaa', cursor: 'pointer', background: date.getDate() % 2 === 0 ? '#fafafa' : '' }} onClick={() => onEdit(date)}>
        <div style={{ height: '32px' }}>{date.getDate()}</div>
        <div style={{ height: '32px' }}>
            <AvatarGroup clients={clients} size='xs' max={5} />
        </div>
    </td>
}

export const Calender = () => {
    const [date, setDate] = useState<Date>(new Date());
    const [editDate, setEditDate] = useState<Date>();
    const clients = useSelector(selectAllClients);
    const { data: sessions } = useGetSessionsQuery({ year: date.getFullYear(), month: date.getMonth() + 1 });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();

    const weeks: Array<JSX.Element[]> = [];

    const daysSessions = (dt: Date) => sessions?.filter(s => dateOnly(s.date).getTime() === dt.getTime());

    const onEditDate = (dt: Date) => {
        setEditDate(dt);
        onOpen();
    }

    let currentDate = dateOnly(new Date(date.getFullYear(), date.getMonth(), 1));
    let week = [];
    for (let i = 0; i < currentDate.getDay(); i++) {
        week.push(<NonDay key={`nd-${i}`} />);
    }
    do {
        const daySessions = daysSessions(currentDate) ?? [];
        const dayClients = daySessions.map(s => clients.find(c => c.id === s.clientId));

        week.push(<Day key={`day-${currentDate.getDay()}`} date={new Date(currentDate)} clients={dayClients} onEdit={onEditDate} />);
        if (currentDate.getDay() === 6) {
            weeks.push(week);
            week = [];
        }
        currentDate.setDate(currentDate.getDate() + 1);
    }
    while (currentDate.getMonth() === date.getMonth());

    weeks.push(week);

    return <Container
        maxW='1000px'
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        boxShadow={'md'}
        rounded={'lg'}
        p={6}>
        <VStack spacing={5}>
            <Flex w="100%" justifyContent="space-between">
                <Button onClick={() => { setDate(addMonths(date, -1)) }}>&lt;</Button>
                <Heading size="lg">{date.toLocaleString('default', { month: 'long', year: 'numeric' })}</Heading>
                <div>
                    <Button onClick={() => { navigate("..") }} leftIcon={<FiList />}></Button>
                    <Button onClick={() => { setDate(addMonths(date, 1)) }}>&gt;</Button>
                </div>
            </Flex>
            <table style={{ width: '100%', border: '1px solid #aaa', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
                <thead style={{ border: '1px solid #aaa' }}>
                    <tr>
                        <th>Sun</th>
                        <th>Mon</th>
                        <th>Tue</th>
                        <th>Wed</th>
                        <th>Thu</th>
                        <th>Fri</th>
                        <th>Sat</th>
                    </tr>
                </thead>
                <tbody>
                    {weeks.map((week, idx) => <tr key={`week-${idx}`}>{week}</tr>)}
                </tbody>
            </table>
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {date.toLocaleString('default', { month: 'long', year: 'numeric', day: 'numeric' })}
                    <ModalCloseButton />
                </ModalHeader>
                <ModalBody>
                    {editDate && <EditSessions sessions={daysSessions(editDate)} clients={clients} date={editDate} />}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClose}>Done</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Container>;
}