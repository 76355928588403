import { Avatar as Av, AvatarGroup as Ag } from "@chakra-ui/react";
import { Client } from "./clientsSlice";

export const Avatar = ({ client, size }: { client: Client, size?: string }) => {
    return <Av name={client.firstName + " " + client.lastName} size={size} />
}

export const AvatarGroup = ({ clients, size, max }: { clients: Array<Client | undefined>, size?: string, max?: number }) => {
    return <Ag size={size} max={max} spacing={-1}>
        {clients
            .filter(c => !!c)
            .sort((a, b) => a?.id === b?.id ? 0 : a?.id! > b?.id! ? 1 : -1)
            .map(c => <Av key={c!.id} name={c!.firstName + " " + c!.lastName} />)}
    </Ag>
}