import { TableContainer, Table, Thead, Tr, Th, Tbody, Td, Container, useColorModeValue, Button, Divider, Stack, Skeleton } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom";
import { formatCurrency } from "../api/apiSlice";
import { Avatar } from "./Avatar";
import { useGetClientListQuery } from "./clientsSlice";

export const List = () => {
  const {data: clients, isFetching} = useGetClientListQuery(undefined, { refetchOnMountOrArgChange: true });

  const navigate = useNavigate();
  const showClient = (id: string) => {
    navigate(`/clients/${id}`);
  }

  const Loading = () => {
      return <Stack>
      <Skeleton height='20px' />
      <Skeleton height='40px' />
      <Skeleton height='40px' />
    </Stack>
  }

  return (
    <Container
      maxW='1000px'
      bg={useColorModeValue('white', 'whiteAlpha.100')}
      boxShadow={'md'}
      rounded={'lg'}
      p={6}>
        {!isFetching && clients ?
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th></Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Due</Th>
              <Th>Outstanding</Th>
              <Th>Rate</Th>
            </Tr>
          </Thead>
          <Tbody>
              {clients.map(client => (
              <Tr onClick={() => showClient(client.id)} key={client.id} style={{cursor: 'pointer'}}>
                <Td><Avatar client={client} /></Td>
                <Td>{client.firstName}</Td>
                <Td>{client.lastName}</Td>
                <Td>{formatCurrency(client.due)}</Td>
                <Td>{formatCurrency(client.outstanding)}</Td>
                <Td>{formatCurrency(client.rate)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
       : <Loading />}
      <Divider />
      <Button onClick={() => navigate('/clients/new')}>Add Client</Button>
    </Container>)
}