import { apiSlice } from "../api/apiSlice";

export interface Session {
    id: string;
    clientId: string;
    date: string;
    invoiceId?: string;
}

export const sessionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSessions: builder.query<Session[], {year: number, month: number}>({
            query: (date) => `sessions/${date.year}/${date.month}`,
            providesTags: ["sessions"],
        }),
        addSession: builder.mutation<Session, Session>({
            query: (session) => ({
                url: `sessions`,
                method: 'POST',
                body: session,
            }),
            invalidatesTags: ["sessions", 'stats'],
        }),
        deleteSession: builder.mutation({
            query: (session) => ({
                url: `sessions/delete/${session.id}`,
                method: 'POST',
            }),
            invalidatesTags: ["sessions", 'stats'],
        })
    })
});

export const { 
    useGetSessionsQuery, 
    useAddSessionMutation,
    useDeleteSessionMutation,
} = sessionApiSlice
