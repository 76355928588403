import { GridItem, Box, Heading, Table, Thead, Tr, Th, Button, Tbody, Td, Tag, Text } from "@chakra-ui/react";
import { FiPlusCircle, FiDownload } from "react-icons/fi";
import { Link } from "react-router-dom";
import { formatDate } from "../api/apiSlice";
import { Invoice } from "../billing/billingSlice";
import { Client, useGetClientInvoicesQuery } from "./clientsSlice";

export const InvoiceList = ({client}: {client: Client}) => {
    const {data} = useGetClientInvoicesQuery(client.id);
  
    const invoiceTotal = (invoice: Invoice) => {
      return invoice.items.reduce((t, i) => t + i.quantity * i.unitPrice, 0)
    }
  
    const statusColor = (invoice: Invoice) => {
      switch(invoice.status) {
        case "OverDue": return "red";
        case "Paid": return "teal";
        default: return "blue";
      }
    }
  
    return <><GridItem colSpan={{ md: 1 }}>
    <Box px={[4, 0]}>
      <Heading fontSize="lg" fontWeight="medium" lineHeight="6">
        Invoices
      </Heading>
      <Text
        mt={1}
        fontSize="sm"
        color="gray.600"
        _dark={{ color: "gray.400" }}
      >
        These are the latest invoices.
      </Text>
    </Box>
  </GridItem>
  <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }}>
    <Box
      px={4}
      py={5}
      p={[null, 6]}
      bg="white"
      _dark={{ bg: "#141517" }}
    >
      <Table>
        <Thead>
          <Tr>
            <Th>Number</Th>
            <Th>Date</Th>
            <Th isNumeric>Amount</Th>
            <Th>Status</Th>
            <Th><Link to={`/billing/${client.id}`}><Button variant="ghost"><FiPlusCircle /></Button></Link></Th>
          </Tr>
        </Thead>
        <Tbody>
          {data?.map(i => <Tr key={i.id}>
            <Td>{i.number}</Td>
            <Td>{formatDate(i.date)}</Td>
            <Td isNumeric>${invoiceTotal(i)}</Td>
            <Td><Tag colorScheme={statusColor(i)}>{i.status}</Tag></Td>
            <Td><a href={`/api/billing/invoices/${i.id}`}><Button><FiDownload /></Button></a></Td>
          </Tr>)}
        </Tbody>
      </Table>
    </Box>
  </GridItem></>
  }