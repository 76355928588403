import { apiSlice } from "../api/apiSlice";

export interface Stats {
    totalDue: number;
    totalOutstanding: number;
    months: Array<{year: number, month: number, total: number, amount: number, earned: number}>;
}

export const homeApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getStats: builder.query<Stats, void>({
            query: () => 'stats',
            providesTags: ["stats"]
        })
    })
});

export const {
    useGetStatsQuery
} = homeApiSlice;