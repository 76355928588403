import { List } from "./list"
import { Routes, Route } from "react-router-dom"
import { Invoice } from "./invoice"

export const Billing = () => {
    return (
        <Routes>
            <Route path=":clientId" element={<Invoice />} />
            <Route index element={<List />} />
        </Routes>
    )    
}