import { Box, Button, Container, Editable, EditableInput, EditablePreview, Heading, Input, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useColorModeValue, useToast } from "@chakra-ui/react"
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { RootState } from "../../app/store";
import { Client, selectClientById } from "../clients/clientsSlice";
import { LineItem, useGetClientOutstandingSessionsQuery, useRaiseInvoiceMutation } from "./billingSlice"
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi" 
import { formatDate } from "../api/apiSlice";
import { FiLoader, FiBriefcase } from "react-icons/fi"

const ItemRow = ({item, updateItem, removeItem}:{
    item: LineItem, 
    updateItem: (item: LineItem) => void,
    removeItem: (item: LineItem) => void 
    }) => {
    const [lineItem, setLineItem] = useState(item);

    useEffect(() => {
        if(JSON.stringify(lineItem) !== JSON.stringify(item) )
        {
            updateItem(lineItem);
        }
    }, [item, lineItem, updateItem]);

    useEffect(() => {
    }, [item.description]);

    const updateUnitPrice = (value: string) => {
        setLineItem({...lineItem, unitPrice: parseFloat(value)});
    }

    const updateQuantity = (value: string) => {
        setLineItem({...lineItem, quantity: parseFloat(value)});
    }

    const updateDescription = (value: string) => {
        setLineItem({...lineItem, description: value});
    }
    
    return <Tr>
        <Td>{formatDate(item.date)}</Td>
        <Td>
            <Editable defaultValue={lineItem.description} startWithEditView={lineItem.description === "New Item"} onSubmit={updateDescription}>
                <EditablePreview />
                <EditableInput />
            </Editable>
        </Td>
        <Td isNumeric w="85px">
            <Editable w="85px" defaultValue={`${lineItem.unitPrice}`} onSubmit={updateUnitPrice}>
                <EditablePreview />
                <Input type="number" variant="unstyled" as={EditableInput} />
            </Editable>
        </Td>
        <Td isNumeric w="85px">
            <Editable w="85px" defaultValue={`${lineItem.quantity}`} onSubmit={updateQuantity}>
                <EditablePreview />
                <Input type="number" min={1} variant="unstyled" as={EditableInput} />
            </Editable>
        </Td>
        <Td isNumeric w="85px">
            {lineItem.unitPrice * lineItem.quantity}
        </Td>
        <Td>
            <Button variant="outline" size="sm"><FiMinusCircle onClick={() => removeItem(item)} /></Button>
        </Td>
    </Tr>
}


export const Invoice = () => {
    const { clientId } = useParams();
    const client = useSelector<RootState, Client | undefined>((state) => selectClientById(state, clientId ?? ""));
    const { data } = useGetClientOutstandingSessionsQuery(clientId ?? "");
    const [ lineItems, setLineItems ] = useState<LineItem[]>([]);
    const [ subTotal, setSubTotal ] = useState<number>(0);
    const toast = useToast();

    const [raiseInvoice, {isLoading}] = useRaiseInvoiceMutation();

    const navigate = useNavigate();

    const handleInvoice = async () => {
        if(clientId) {
            const invoice = await raiseInvoice({clientId, items: lineItems}).unwrap();

            var xhr = new XMLHttpRequest();
            xhr.open('GET', `/api/billing/invoices/${invoice?.id}`, true);
            xhr.responseType = 'blob';
            xhr.onload = function(e) {
              if (this.status === 200) {
                var myBlob = this.response;
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(myBlob);
                link.click();
              }
            };
            xhr.send();

            toast({
                title: "Invoice Raised",
                colorScheme: "teal",
                description: `An invoice has been successfully rased`
            });
            navigate(`/billing`);
        }
    }

    const updateTotals = useCallback(() => {
        var subTotal = lineItems.reduce((total, li) => total + (li.unitPrice * li.quantity), 0);
        setSubTotal(subTotal);
    }, [lineItems]);
    
    useEffect(() => {
        setLineItems(data?.map((session, idx) => ({
            id: idx.toString(),
            date: session.date, 
            sessionId: session.id,
            description: client?.defaultSession, 
            quantity: 1, 
            unitPrice: client?.rate } as LineItem)) ?? [])
    }, [client?.defaultSession, client?.rate, data]);

    useEffect(() => {
        updateTotals();
    }, [lineItems, updateTotals]);

    const updateItem = (item: LineItem) => {
        setLineItems(lineItems.map(i => i.id === item.id ? item : i));
        updateTotals();
    }

    const addItem = () => {
        setLineItems([...lineItems, {id: Date.now().toString(), description: "New Item", unitPrice: 0, quantity: 1}]);
    }

    const removeItem = (item: LineItem) => {
        setLineItems(lineItems.filter(i => i.id !== item.id))
    }

    return <Container
        w="100%"
        maxW="1000px"
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        boxShadow={'md'}
        rounded={'lg'}
        p={6}>
            <Heading size="ms">{client?.firstName} {client?.lastName}</Heading>
        <TableContainer>
            <Table>
                <Thead>
                    <Tr>
                        <Th w="150px">Session Date</Th>
                        <Th>Description</Th>
                        <Th isNumeric w="85px">Unit Price</Th>
                        <Th isNumeric w="85px">Qty</Th>
                        <Th isNumeric w="85px">Total</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {lineItems?.map((item, idx) => <ItemRow key={item.id} item={item} updateItem={updateItem} removeItem={removeItem} />)}
                </Tbody>
                <Tfoot>
                    <Tr>
                        <Td></Td>
                        <Td></Td>
                        <Td colSpan={2}>TOTAL</Td>
                        <Td isNumeric>{subTotal}</Td>
                        <Td>
                            <Button variant="outline" size="sm" onClick={addItem}><FiPlusCircle /></Button>
                        </Td>
                    </Tr>
                </Tfoot>
            </Table>
        </TableContainer>
        <Box display="flex" p={3} justifyContent="flex-end" >
            <Button onClick={handleInvoice} disabled={isLoading || lineItems.length < 1}>
                {isLoading ? <FiLoader className="spin" /> : <FiBriefcase />}&nbsp;Invoice
            </Button>
        </Box>
    </Container>
}