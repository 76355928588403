import { Container, useColorModeValue, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Skeleton, Stack, Box, Heading, useToast } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Client, selectAllClients } from "../clients/clientsSlice";
import { Invoice, SessionToBeBulled, useGetOutstandingSessionsQuery, useRaiseInvoiceMutation } from "./billingSlice";
import { FiLoader, FiBriefcase, FiSun } from "react-icons/fi"
import { Avatar } from "../clients/Avatar";
import { formatCurrency } from "../api/apiSlice";

export const List = () => {
    const clients = useSelector(selectAllClients);
    const {data: sessions, isFetching} = useGetOutstandingSessionsQuery();

    const Loading = () => {
        return <Stack>
        <Skeleton height='20px' />
        <Skeleton height='40px' />
        <Skeleton height='40px' />
      </Stack>
    }

    const findClient = (session: SessionToBeBulled) => {
        return clients.find(c => c.id === session.clientId) ?? {id: session.clientId, firstName: "Unknown", lastName: "Unknown", rate: 45} as Client
    }

    const BillingTable = () => {
        return <TableContainer>
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th>Client</Th>
                        <Th isNumeric>Sessions</Th>
                        <Th isNumeric>Total</Th>
                        <Th></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {sessions?.map(s => <ClientRow key={s.clientId} session={s} client={findClient(s)} />)}
                </Tbody>
            </Table>
        </TableContainer>
    }

    const Empty = () => {
        return <Box p={5} color="gray.100" display="flex" flexDirection="column" alignItems="center">
            <Heading size='4xl'><FiSun /></Heading>
            <Heading>All done</Heading>
        </Box>
    }

    return (
        <Container
            maxW='1000px'
            bg={useColorModeValue('white', 'whiteAlpha.100')}
            boxShadow={'md'}
            rounded={'lg'}
            p={6}>
                {isFetching ? <Loading /> : <BillingTable />}
                {!isFetching && sessions && sessions.length < 1 && <Empty />}
        </Container>)
}


const ClientRow = ({session, client}:{session: SessionToBeBulled, client: Client}) => {
    const [raiseInvoice, {isLoading}] = useRaiseInvoiceMutation();
    const toast = useToast();

    const navigate = useNavigate();
    const showClient = (id: string) => {
        navigate(`/billing/${id}`);
    }

    const handleInvoice = async (clientId: string) => {
        const invoice = await raiseInvoice({clientId, items: []} as Invoice).unwrap();
        const link = document.createElement('a');
        link.href = `/api/billing/invoices/${invoice?.id}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast({
            title: "Invoice Raised",
            colorScheme: "teal",
            description: `An invoice has been successfully rased`
          });
    }

    const total = client.rate * session.sessionCount;
    return <Tr onClick={() => showClient(client.id)} key={client.id}>
        <Td><Avatar client={client} /></Td>
        <Td>{client.firstName} {client.lastName}</Td>
        <Td isNumeric>{session.sessionCount}</Td>
        <Td isNumeric>{formatCurrency(total)}</Td>
        <Td>
            <Button onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleInvoice(client.id);}} disabled={isLoading}>
                {isLoading ? <FiLoader className="spin" /> : <FiBriefcase />}&nbsp;Invoice
            </Button>
        </Td>
    </Tr>
}