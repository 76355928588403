import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import { User } from './User';

export const formatCurrency = (amount: number) => amount.toLocaleString(undefined, {style: "currency", currency: "NZD", currencyDisplay: "narrowSymbol"});

export const formatDate = (date: string | undefined) => {
  return date ? new Date(date).toLocaleDateString() : "";
}

export const dateOnly = (date: string | Date | null) => {
  const dateVal = date ? new Date(date) : new Date();
  return new Date(Date.UTC(dateVal.getUTCFullYear(), dateVal.getUTCMonth(), dateVal.getUTCDate()));
}

export const today = () => {
  return dateOnly(new Date());
}

export const addDays = (date: Date, days: number) => {
  var d = new Date(date);
  d.setUTCDate(d.getUTCDate() + days);
  return dateOnly(d);
}

export const addMonths = (date: Date, months: number) => {
  var d = new Date(date);
  d.setMonth(d.getMonth() + months);
  return d;
}

export const toMonth = (month: number) => Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(2020, month - 1, 1));

const baseQuery = fetchBaseQuery({ baseUrl: '/api/', redirect: 'error' })
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  let status = result.meta?.response?.status ?? 500;
  if (status >= 300 && status <= 500) {
    console.log("Redirecting to login page");
    window.location.href = "/api/auth/login";
  }
  return result
}

// Define a service using a base URL and expected endpoints
export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithReauth,
  tagTypes: ["user", "clients", "sessions", "receipts", "invoices", "stats", "credit-notes"],
  endpoints: (builder) => ({
    getMe: builder.query<User, void>({
      query: () => `me`,
      providesTags: ["user"],
    }),
  }),
})

export const { useGetMeQuery } = apiSlice