import { Route, Routes } from "react-router-dom"
import { Calender } from "./Calender"
import { Day } from "./Day"

export const SessionLog = () => {
    return (
        <Routes>
            <Route path="calender" element={<Calender />} />
            <Route index element={<Day />} />
        </Routes>
    )
}