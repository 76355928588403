import {
  Box,
  Button,
  GridItem,
  SimpleGrid,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "../../app/store";
import { Client, selectClientById } from "./clientsSlice";
import { ClientDetails } from "./ClientDetails";
import { InvoiceList } from "./InvoiceList";
import { SessionList } from "./SessionList";
import { ReceiptList } from "./ReceiptList";
import { CreditNoteList } from "./CreditNoteList";
import { useState } from "react";
import { AccountSummary } from "./AccountSummary";

export const EditClient = () => {
  const { clientId } = useParams();
  const [showDetails, setShowDetails] = useState(false);
  const currentClient = useSelector<RootState, Client>((state) => selectClientById(state, clientId ?? "new")
    ?? { id: "new", email: "", firstName: "", lastName: "", rate: 45, defaultSession: "Reformer & mat 1 hr. session", due: 0, outstanding: 0 });

  return (
    <>
      <Box bg="#edf3f8" _dark={{ bg: "#111" }} p={10}>
        <Box mt={[10, 0]}>
          <SimpleGrid
            display={{ base: "initial", md: "grid" }}
            columns={{ md: 3 }}
            spacing={{ md: 6 }}
          >
            <ClientDetails currentClient={currentClient} />
            {(currentClient.id !== "new") && <>
              <AccountSummary client={currentClient} />
              {!showDetails && <>
                <GridItem colSpan={{ md: 1 }}></GridItem>
                <GridItem mt={[5, null, 0]} colSpan={{ md: 2 }} textAlign="center">
                  <Button variant="outline" colorScheme="teal" onClick={() => setShowDetails(true)}>Load Details</Button>
                </GridItem>
              </>}
              {showDetails && <>
                <SessionList client={currentClient} />
                <InvoiceList client={currentClient} />
                <ReceiptList client={currentClient} />
                <CreditNoteList client={currentClient} />
                </>
              }
            </>}
          </SimpleGrid>
        </Box>
      </Box>
    </>
  );
};
