import { Container, useColorModeValue, TableContainer, Table, Thead, Tr, Th, Tbody, Td, Button, Skeleton, Stack, FormControl, Input, Box, Heading, useToast } from "@chakra-ui/react"
import { useSelector } from "react-redux";
import { Client, selectAllClients } from "../clients/clientsSlice";
import { FiLoader, FiDollarSign, FiSun } from "react-icons/fi"
import { Unpaid, useGetUnpaidAmountsQuery, useReceivePaymentMutation } from "./receiptingSlice";
import { useState } from "react";
import { Avatar } from "../clients/Avatar";
import { formatCurrency } from "../api/apiSlice";

export const Receipting = () => {
    const clients = useSelector(selectAllClients);
    const {data: amounts, isFetching} = useGetUnpaidAmountsQuery();

    const Loading = () => {
        return <Stack>
        <Skeleton height='20px' />
        <Skeleton height='40px' />
        <Skeleton height='40px' />
      </Stack>
    }

    const findClient = (session: Unpaid) => {
        return clients.find(c => c.id === session.clientId) ?? {id: session.clientId, firstName: "Unknown", lastName: "Unknown", rate: 45} as Client
    }

    const Empty = () => {
        return <Box p={5} color="gray.100" display="flex" flexDirection="column" alignItems="center">
            <Heading size='4xl'><FiSun /></Heading>
            <Heading>All done</Heading>
        </Box>
    }

    return (
        <Container
            maxW="1000px"
            bg={useColorModeValue('white', 'whiteAlpha.100')}
            boxShadow={'md'}
            rounded={'lg'}
            p={6}>
                {isFetching 
                ? <Loading />
                : <TableContainer>
                    <Table variant='simple'>
                        <Thead>
                            <Tr>
                                <Th></Th>
                                <Th>Client</Th>
                                <Th isNumeric>Outstanding</Th>
                                <Th isNumeric>Paid</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {amounts?.map(a => <ClientRow key={a.clientId} unpaid={a} client={findClient(a)} />)}
                        </Tbody>
                    </Table>
                </TableContainer>}
                {!isFetching && amounts && amounts.length < 1 && <Empty />}
        </Container>)
}


const ClientRow = ({unpaid, client}:{unpaid: Unpaid, client: Client}) => {
    const [receivePayment, {isLoading}] = useReceivePaymentMutation();
    const [amount, setAmount] = useState<number>(unpaid.amount);
    const toast = useToast();

    const handlePayment = async (clientId: string) => {
        await receivePayment({clientId, amount}).unwrap();
        toast({
            title: "Receipt Created",
            colorScheme: "teal",
            description: `A receipt for $${amount} has been created`
        });
    }

    return <Tr key={client.id}>
        <Td><Avatar client={client} /></Td>
        <Td>{client.firstName} {client.lastName}</Td>
        <Td isNumeric>{formatCurrency(unpaid.amount)}</Td>
        <Td isNumeric><FormControl><Input type="number" value={amount} onChange={e => setAmount(e.target.valueAsNumber)} w="20" /></FormControl></Td>
        <Td>
            <Button onClick={(e) => {e.preventDefault(); e.stopPropagation(); handlePayment(client.id);}} disabled={isLoading}>
                {isLoading ? <FiLoader className="spin" /> : <FiDollarSign />}&nbsp;Receipt
            </Button>
        </Td>
    </Tr>
}